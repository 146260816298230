import type { CommentFlag } from "@fscrypto/domain/comments";
import { createStore, useStore } from "@fscrypto/state-management";
import { useEffect } from "react";
import { commentsClient } from "../data/comments-client";

const flagStore = createStore<CommentFlag[]>([]);

export const useFlags = (config?: { data: CommentFlag[] }) => {
  const flags = useStore(flagStore);

  useEffect(() => {
    if (config?.data) {
      const mergedFlags = [
        ...flags.filter((flag) => !config.data.find((newFlag) => newFlag.id === flag.id)),
        ...config.data,
      ];
      flagStore.set(mergedFlags);
    }
  }, [config?.data]);

  const add = async (commentId: string, reason: string) => {
    const newFlag = await commentsClient.flag(commentId, reason);
    flagStore.set([...flagStore.get(), newFlag]);
  };

  return {
    add,
    flags,
  };
};

import type { Comment } from "@fscrypto/domain/comments";

import { createStore, useStore } from "@fscrypto/state-management";
import { useEffect } from "react";
import { useCurrentUser } from "~/features/current-user";
import { createFetchCache } from "~/utils/fetch-cache";
import { commentsClient } from "../data/comments-client";
import { useFlags } from "./useFlags";

const commentStore = createStore<Record<string, Comment[]>>({});
const commentCountStore = createStore<Record<string, number>>({});

const updateCommentStores = (id: string, comments: Comment[]) => {
  commentStore.set({ ...commentStore.get(), [id]: comments });
  commentCountStore.set({ ...commentCountStore.get(), [id]: comments.length });
};

export const useComment = (id: string) => {
  const store = useStore(commentStore);
  const allComments = Object.values(store).reduce((acc, comments) => [...acc, ...comments], []);
  const comment = allComments.find((comment) => comment.id === id);
  const currentUser = useCurrentUser();
  const { add, flags } = useFlags();
  const isFlaggedByCurrentUser = flags.some(
    (flag) => flag.createdById === currentUser.currentUser.id && flag.commentId === id,
  );

  const flag = (reason: string) => add(id, reason);

  return {
    comment,
    flag,
    isFlaggedByCurrentUser,
  };
};

export const useComments = (
  resourceType: Comment["resourceType"],
  resourceId: string,
  config?: { data?: Comment[]; countOnly?: boolean },
) => {
  const id = `${resourceType}-${resourceId}`;
  const allComments = useStore(commentStore);
  const comments = allComments[id] || [];
  const count = useStore(commentCountStore)[id] || 0;
  const fetchComments = createFetchCache<{ comments: Comment[] }>();
  const fetchCount = createFetchCache<{ count: number }>();

  useEffect(() => {
    const existingComments = allComments[id];

    if (!existingComments) {
      commentStore.set({ ...commentStore.get(), [id]: [] });
    }

    if (config?.data) {
      const mergedComments = [
        ...comments.filter((comment) => !config?.data?.find((newComment) => newComment.id === comment.id)),
        ...config.data,
      ];
      updateCommentStores(id, mergedComments);
    }

    // If this is the initial setup of this hook and no data is provided, fetch the comments (or just the count)
    if (!existingComments && !config?.data) {
      if (!config?.countOnly) {
        fetchComments(id, () => commentsClient.get(resourceId)).then(({ comments }) => {
          updateCommentStores(id, comments);
        });
      } else {
        fetchCount(id, () => commentsClient.getCount(resourceId)).then(({ count }) => {
          commentCountStore.set({ ...commentCountStore.get(), [id]: count });
        });
      }
    }
  }, [id, config?.data]);

  const add = async (content: string) => {
    const newComment = await commentsClient.add({ content, resourceId, resourceType });
    const existingComments = commentStore.get()[id] || [];
    updateCommentStores(id, [...existingComments, newComment]);
  };

  return {
    add,
    comments,
    count,
  };
};

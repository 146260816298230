import type { Comment, CommentFlag, NewComment } from "@fscrypto/domain/comments";
import { commentFlagSchema, schema } from "@fscrypto/domain/comments";
import { Client, HttpClient } from "@fscrypto/http";
import { $path } from "remix-routes";
import { z } from "zod";

export class CommentsClient {
  constructor(private client: HttpClient) {}

  get(resourceId: string) {
    return this.client.get<{ comments: Comment[] }>(
      $path("/api/comments/resource/:id", { id: resourceId }),
      z.object({ comments: z.array(schema) }),
    );
  }

  getCount(resourceId: string) {
    return this.client.get<{ count: number }>(
      $path("/api/comments/resource/:id/count", { id: resourceId }),
      z.object({ count: z.number() }),
    );
  }

  add(payload: NewComment) {
    return this.client.post<Comment>($path("/api/comments"), payload, schema);
  }

  flag(id: string, reason: string) {
    return this.client.post<CommentFlag>($path("/api/comments/:id/flag", { id }), { reason }, commentFlagSchema);
  }
}

export const commentsClient = new CommentsClient(new Client());
